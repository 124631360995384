<template>
  <div>
    <heading>Wilo Pumpe</heading>
    <template v-if="dataLoaded">
      <ToggleItem
        v-if="thing.features !== null"
        :value="thing.features.WiloPump.properties.configuration.running"
        displayName="Einschaltbefehl"
        featureName="WiloPump"
      />
      <divider></divider>
      <div class="inline-input-group flex-wrap">
        <span class="mr-2 mb-1">Regelungsart</span>
        <b-form-select
          v-model="selectedControlMode"
          :disabled="loading"
          :options="controlModes"
          size="sm"
          @change="onControlModeChanged"
        >
          <template #first>
            <option disabled value="">{{ $t('dropdown_please_select') }}</option>
          </template>
        </b-form-select>
        <SubmitState
          :errorMessage="controlModeSubmitError"
          :state="controlModeSubmitState"
          @timeout-end="controlModeSubmitState = 'idle'"
        ></SubmitState>
      </div>
      <div class="inline-input-group flex-wrap">
        <div class="inputs-grid">
          <b>Aktueller Wert</b>
          <b>Min</b>
          <b>Sollwert</b>
          <b>Max</b>

          <template v-if="loading">
            <b-skeleton v-for="x in 4" :key="x"></b-skeleton>
          </template>
          <template v-else>
            <span>{{ format(initialSetpoint) }}</span>
            <span>{{ format(minValue) }}</span>
            <UnitInput
              v-model="setpoint"
              :baseUnit="unit"
              :max="maxValue"
              :min="minValue"
              :step="null"
              class="input-component"
              @value-changed="onValueChange"
              @valid-changed="inputValidations = $event"
            />
            <span>{{ format(maxValue) }}</span>
            <!-- Second row -->
            <span class="text-muted">
              {{ ((initialSetpoint / max100Value) * 100).toFixed(2) }}%
            </span>
            <span class="text-muted">{{ (minDutyPoint * 100).toFixed(2) }}%</span>
            <span class="text-muted">{{ ((setpoint / max100Value) * 100).toFixed(2) }}%</span>
            <span class="text-muted">{{ (maxDutyPoint * 100).toFixed(2) }}%</span>
          </template>
        </div>

        <SubmitState
          :errorMessage="setpointSubmitError"
          :state="setpointSubmitState"
          class="w-100"
          @timeout-end="setpointSubmitState = 'idle'"
        />

        <b-form-invalid-feedback
          :state="inputValidations.belowMax || !inputValidations.valid"
          class="w-100"
        >
          {{ $t('feedback_above_max', { max: format(maxValue) }) }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="inputValidations.valid" class="w-100">
          {{ $t('feedback_invalid_number') }}
        </b-form-invalid-feedback>

        <b-form-invalid-feedback
          :state="!inputValidations.valid || inputValidations.aboveMin"
          class="w-100"
        >
          {{ $t('feedback_below_min', { min: format(minValue) }) }}
        </b-form-invalid-feedback>
      </div>
      <template v-if="loading"></template>
      <template v-else></template>
    </template>
    <div class="w-100 d-flex justify-content-end">
      <b-button size="sm" @click="goToTwin">
        {{ $t('open_digital_twin') }}
        <span class="material-icons">chevron_right</span>
      </b-button>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Watch } from 'vue-property-decorator'
  import { mixins } from 'vue-class-component'
  import BaseTemplate from '@/components/site/scada/command_response/BaseTemplate.vue'
  import ToggleItem from '@/components/site/scada/command_response/toolbox/ToggleItem.vue'
  import NumericValueItem from '@/components/site/scada/command_response/toolbox/NumericValueItem.vue'
  import EnumItem from '@/components/site/scada/command_response/toolbox/EnumItem.vue'
  import SubmitOverlay from '@/components/site/scada/command_response/toolbox/base/SubmitOverlay.vue'
  import Logger from '@/logger'
  import UnitInput from '@/components/site/digital_twins/editor/UnitInput.vue'
  import events from '@/events'
  import SubmitState from '@/components/site/scada/command_response/toolbox/base/SubmitState.vue'
  import Heading from '@/components/site/scada/command_response/toolbox/base/Heading.vue'
  import Divider from '@/components/site/scada/command_response/toolbox/base/Divider.vue'
  import { WiloUnits } from '@/modules/units/WiloUnits'
  import type { Language } from '@/modules/translations/PropertyTranslationService'
  import UnitHandler from '@/modules/units/UnitHandler'
  import ApiMixin from '@/modules/tools/ApiMixin'

  /**  Enum indices for the currently supported control modes */
  const SUPPORTED_MODES = [1, 3, 4, 6, 8, 80]

  // Name of the enum for looking up the control modes
  const WILO_PUMP_MODE_ENUM_NAME = 'WiloPumpControlMode'

  type State = 'idle' | 'submitting' | 'success' | 'error'
  @Component({
    components: {
      Divider,
      Heading,
      SubmitState,
      UnitInput,
      SubmitOverlay,
      EnumItem,
      ToggleItem,
      NumericValueItem,
    },
  })
  export default class WiloPump extends mixins(BaseTemplate, ApiMixin) {
    selectedControlMode = 1

    /** Minimum absolute value, calculated as minDutyPoint * max100Value */
    minValue = 0

    /** Minimum relative setting of the pump (range [0,1]) */
    minDutyPoint = 0

    /** Maximum relative (percentage) setting of the pump (range [0,1]) */
    maxDutyPoint = 1

    /** The maximum absolute value, calculated as maxDutyPoint * max100Value */
    maxValue = 1

    /** Enum index for the unit currently used by the pump */
    unitCode = '0'

    /** Current value in absolute units retrieved from the pump/set by the user */
    setpoint = 1

    /** Initial value loaded from ditto */
    initialSetpoint = 0.5

    /** Validation state of the numeric input */
    inputValidations = {
      valid: true,
      belowMax: true,
      aboveMin: true,
      inStep: true,
    }

    controlModeSubmitState: State = 'idle'

    controlModeSubmitError = ''

    setpointSubmitState: State = 'idle'

    setpointSubmitError = ''

    private unitHandler = UnitHandler.getInstance()

    get unit(): string {
      return WiloUnits[this.unitCode] ?? ''
    }

    /** Reference value for a duty point of 100% */
    get max100Value() {
      return this.maxValue / this.maxDutyPoint
    }

    get step() {
      return Math.round((this.maxValue - this.minValue) * 100) / 100
    }

    /**
     * Resolves the names and indices of all supported control modes for the pump in the language set by the user
     */
    get controlModes() {
      const languageCode = (this.$i18n.locale()?.substring(0, 2) ?? 'de') as Language
      return SUPPORTED_MODES.map((modeEnumIndex) => {
        const text = this.$valueTranslation.translateEnum(
          WILO_PUMP_MODE_ENUM_NAME,
          String(modeEnumIndex),
          languageCode,
          String(modeEnumIndex),
        )
        return { value: modeEnumIndex, text }
      })
    }

    get loading() {
      return this.controlModeSubmitState == 'submitting'
    }

    onValueChange() {
      this.submitEventBus.$emit(events.scada.templates.itemChanged)
    }

    async created() {
      this.submitEventBus.$on(events.scada.templates.submit, this.onSubmit)
    }

    beforeDestroy() {
      this.submitEventBus.$off(events.scada.templates.submit, this.onSubmit)
    }

    async onSubmit() {
      try {
        this.setpointSubmitState = 'submitting'
        await this.getThingApi().updateProperty(
          this.thingId,
          'WiloPump',
          'configuration',
          'dutyPoint',
          String(this.setpoint / this.max100Value),
          '',
        )
        this.setpointSubmitState = 'success'
        this.initialSetpoint = this.setpoint
        this.$emit('submit-finished', true)
      } catch (e) {
        this.setpointSubmitState = 'error'
        this.setpointSubmitError = e.message ?? e.toString()
        this.$emit('submit-finished', false)
      }
    }

    format(value: number) {
      return this.unitHandler.format(this.unit, 'float', value)
    }

    async onControlModeChanged(value: number) {
      this.controlModeSubmitState = 'submitting'
      try {
        await this.getThingApi().updateProperty(
          this.thingId,
          'WiloPump',
          'configuration',
          'controlModeCode',
          String(value),
          '',
        )
        this.thing = await this.getThingApi().fetchById(this.thingId)
      } catch (e) {
        Logger.error('Could not send message to thing', e)
        this.controlModeSubmitError = e.response?.error ?? e.message ?? e.toString()
        this.controlModeSubmitState = 'error'
      }
    }

    @Watch('thing.features.WiloPump.properties.configuration.controlModeCode')
    loadValuesFromThing() {
      const pumpStatus = this.thing.features?.WiloPump?.properties?.status
      const pumpConfig = this.thing.features?.WiloPump?.properties?.configuration

      this.minValue = (pumpStatus?.minValue.value as number) ?? 0
      this.minDutyPoint = (pumpStatus?.minDutyPoint.value as number) ?? 0
      this.maxValue = (pumpStatus?.maxValue.value as number) ?? 10
      this.unitCode = (pumpStatus?.unit.value as string) ?? 0
      this.setpoint = (pumpStatus?.value.value as number) ?? 1
      this.initialSetpoint = (pumpStatus?.value.value as number) ?? 0.5
      this.selectedControlMode = Number.parseInt(pumpConfig?.controlModeCode?.value as string) ?? 1
    }

    goToTwin() {
      this.$router.push({
        name: 'DigitalTwins',
        query: { thing: this.thingId, feature: 'WiloPump' },
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../../../vars';
  @import '../toolbox/item';

  .inputs-grid {
    width: 100%;
    display: grid;
    grid-template-columns: auto min-content auto min-content;
    grid-template-rows: auto auto auto;
    white-space: nowrap;
    column-gap: 1rem;
    align-items: center;
  }

  .inline-input-group {
    @extend .spacer-bottom;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
