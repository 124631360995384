import type {
  PaginationParameters,
  SiteDetailParameters,
  SiteFilterParameters,
} from '@/api/klempner/apiClients/SiteFilterOptionsBuilder'
import type { Site } from '@/api/klempner/models/SiteListResponse'
import type { SiteDashboardStatsResponse } from '@/api/klempner/models/SiteDashboardStatsResponse'
import type { SitePatchParameters } from '@/api/klempner/models/SitePatchParameters'
import { ApiClient } from '@/api/klempner/apiClients/ApiClient'
import type { SiteListResponse } from '@/api/klempner/models/SiteListResponse'

export class SiteApiClient extends ApiClient {
  constructor(tokenProvider: () => string) {
    super(tokenProvider)
  }

  public async list(
    options?: SiteFilterParameters | PaginationParameters | SiteDetailParameters,
  ): Promise<SiteListResponse> {
    const request = await this.doRequest(`sites`, 'GET', options)
    return await request.json()
  }

  public async count(options?: SiteFilterParameters): Promise<number> {
    const request = await this.doRequest('sites/count', 'GET', options)
    return await request.json()
  }

  public async fetchById(id: string, options?: SiteDetailParameters): Promise<Site> {
    const request = await this.doRequest(`site/${id}`, 'GET', options)
    return await request.json()
  }

  public async dashboardStats(): Promise<SiteDashboardStatsResponse> {
    const request = await this.doRequest(`sites/dashboard`, 'GET')
    return await request.json()
  }

  async update(id: string, params: SitePatchParameters) {
    return this.doRequest(`site/${id}`, 'PATCH', {}, params)
  }

  async delete(id: string) {
    return this.doRequest(`site/${id}`, 'DELETE', {}, {})
  }
}
