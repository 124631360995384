export interface SiteSummaryProperty {
  thing_id: string

  feature: string

  type: string

  property: string
}

export interface SiteSummary {
  displayName: string

  value: SiteSummaryProperty

  setpoint: SiteSummaryProperty | null
}

export interface SiteLocation {
  street: string | null

  postalcode: string | null

  city: string | null

  country: string | null

  latitude: number | null

  longitude: number | null
}

export interface SiteGuacamoleDetails {
  name: string

  id: string
}

export interface SiteNotificationCount {
  warning: number

  error: number

  critical: number

  info: number
}

export enum SiteRecommendationMode {
  UNAVAILABLE = 'unavailable',
  DISABLED = 'disabled',
  MANUAL = 'manual',
  AUTO = 'auto',
}

export enum SiteConnectionStatus {
  CONNECTED = 'connected',
  UNKNOWN = 'unknown',
  DISCONNECTED = 'disconnected',
}

export interface ThingProperty {
  value: unknown

  value_type: string

  value_unit: string

  writable: boolean

  commandable: boolean
}

export interface ThingNumberProperty extends ThingProperty {
  value: number

  min: number

  max: number

  scaling: number
}

export interface Thing {
  model: string

  manufacturer: string

  serial_number: string

  name: string

  description: string

  id: string

  definition: string

  category: string

  features: Record<string, Feature>

  last_modified_at: string | null

  created_at: string | null

  customer_id: string

  extra_fields: Record<string, unknown>
}

export type Feature = {
  name: string
  properties: Record<string, Record<string, ThingProperty>>
}

export interface SiteName {}

export interface Site extends SiteName {
  id: string

  name: string

  hidden: boolean

  active: boolean

  description: string

  connection_status: SiteConnectionStatus

  summaries: SiteSummary[]

  location: SiteLocation

  guacamole: SiteGuacamoleDetails[]

  notification_count: SiteNotificationCount

  things: Record<string, Thing>

  maintenance_mode: boolean

  building: Record<string, string | number | boolean>

  customer_id: string

  recommendation_mode: SiteRecommendationMode
}

export interface SiteListResponse {
  sites: Site[]

  total_site_count: number | null
}
