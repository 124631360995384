<template>
  <div class="d-flex justify-content-between align-items-center flex-wrap spacer-bottom">
    <div class="flex-grow-1 d-flex align-items-center">
      <span>{{ displayName }}</span>
      <span v-if="changed">*</span>
      <tool-tip :toolTipText="toolTip" />
    </div>
    <UnitInput
      v-model="currentValue"
      :baseUnit="unit"
      :currentValue="currentValue"
      :max="max"
      :min="min"
      :step="adjustedStep"
      class="input-component"
      number
      size="sm"
      type="number"
      @value-changed="onChange"
      @valid-changed="onValidChanged"
    />
    <span v-if="valueChangedInDitto" class="form-feedback text-warning">
      {{ $t('value_changed_to', { value: format(currentDittoValue) }) }}
    </span>
    <p v-if="!valid.valid" class="form-feedback text-danger">{{ $t('feedback_invalid_number') }}</p>
    <p v-if="valid.valid && !valid.belowMax" class="form-feedback text-danger">
      {{ $t('feedback_above_max', { max: format(max) }) }}
    </p>
    <p v-if="valid.valid && !valid.aboveMin" class="form-feedback text-danger">
      {{ $t('feedback_below_min', { min: format(min) }) }}
    </p>
    <p
      v-if="valid.valid && !valid.inStep && valid.belowMax && valid.aboveMin"
      class="form-feedback text-danger"
    >
      {{
        $t('feedback_not_in_step', {
          step: format(adjustedStep),
          nextSmaller: format(lastStep),
          nextBigger: format(nextStep),
        })
      }}
    </p>
    <SubmitState
      :errorMessage="submitError"
      :state="submitState"
      class="w-100"
      @timeout-end="submitState = 'idle'"
    ></SubmitState>
  </div>
</template>

<script lang="ts">
  import { Component, InjectReactive, Prop, Watch } from 'vue-property-decorator'
  import BaseItem from '@/components/site/scada/command_response/toolbox/base/BaseItem.vue'
  import { mixins } from 'vue-class-component'
  import ToolTip from '@/components/site/scada/command_response/toolbox/base/ToolTip.vue'
  import UnitHandler from '@/modules/units/UnitHandler'
  import { Thing } from '@/api/klempner/models/SiteListResponse'
  import { resolve } from '@/modules/tools/Resolve'
  import UnitInput from '@/components/site/digital_twins/editor/UnitInput.vue'
  import SubmitState from '@/components/site/scada/command_response/toolbox/base/SubmitState.vue'
  import events from '@/events'

  /**
   * Component for configuring a numeric value of a ditto thing.
   */
  @Component({
    name: 'NumericValueItem',
    components: {
      SubmitState,
      ToolTip,
      UnitInput,
    },
  })
  export default class NumericValueItem extends mixins(BaseItem) {
    @Prop()
    readonly maxValue!: number | string

    @Prop({ required: false })
    readonly step!: number | 'any'

    /**
     * Value of the target property in Ditto before the user started editing the value in the UI
     */
    initialDittoValue: number = 0

    /**
     * Current value from Ditto
     */
    currentDittoValue: number = 0

    @Prop({ required: true })
    private valuePath!: string

    @Prop()
    private minValue!: number | string

    private valid = {
      valid: true,
      belowMax: true,
      aboveMin: true,
      inStep: true,
    }

    /** Current value as entered by the user & shown in the modal */
    private currentValue: number | '' = 0

    private unitHandler = UnitHandler.getInstance()

    @InjectReactive()
    private thing!: Thing

    private metadata: {
      unit: string
      type: string
      max?: number
      min?: number
      scaling?: number
    } | null = null

    /** Gets the next possible step value smaller than the current value */
    get lastStep() {
      if (this.adjustedStep == null || typeof this.currentValue == 'string') {
        return 0
      }
      const min = typeof this.min === 'number' ? this.min : 0
      return (
        (Math.floor(((this.currentValue - min) * 100) / this.adjustedStep) * this.adjustedStep) /
          100 +
        min
      )
    }

    /** The next possible step value greater than the current value */
    get nextStep() {
      if (this.adjustedStep == null || typeof this.currentValue == 'string') {
        return 0
      }
      const min = typeof this.min === 'number' ? this.min : 0
      return (
        (Math.ceil(((this.currentValue - min) * 100) / this.adjustedStep) * this.adjustedStep) /
          100 +
        min
      )
    }

    get unit() {
      return this.metadata?.unit ?? ''
    }

    get max() {
      return NumericValueItem.parseValue(this.maxValue, this.metadata?.max)
    }

    get min() {
      return NumericValueItem.parseValue(this.minValue, this.metadata?.min)
    }

    /**
     *  The step size for the input, either from the step property or the scaling value in the metadata
     */
    get adjustedStep() {
      if (this.step != undefined) {
        if (typeof this.step == 'number') {
          return this.step
        }
        if (this.step == 'any') {
          return null
        }
        return this.step
      }
      return this.metadata?.scaling ?? (this.unit === 'percent' ? 0.01 : 1)
    }

    get messageBody() {
      if (this.msgParamName != null) {
        return { [this.msgParamName]: this.currentValue }
      } else {
        return JSON.stringify(this.currentValue)
      }
    }

    get commandBody() {
      if (this.currentValue === '') {
        return undefined
      }
      return this.currentValue
    }

    get hasValue() {
      return this.currentValue !== ''
    }

    get valueChangedInDitto() {
      return this.changed && this.currentDittoValue !== this.initialDittoValue
    }

    /**
     * Parses the given property as a string or number, falls back to the metadata value
     */
    private static parseValue(
      propValue?: number | string,
      metadataValue?: number,
    ): number | string | null {
      if (typeof propValue == 'string') {
        const parsedValue = Number(propValue)
        if (!Number.isNaN(parsedValue)) {
          return parsedValue
        }
      }
      return propValue ?? metadataValue ?? null
    }

    mounted() {
      this.changed = false
      this.onValueChanged()
    }

    activated() {
      this.changed = false
      this.onValueChanged()
    }

    @Watch('thing', { deep: true })
    onValueChanged() {
      const value = resolve(this.valuePath, this.thing, '.')
      this.metadata = resolve('_metadata.' + this.valuePath, this.thing, '.')
      this.currentDittoValue = value

      if (!this.changed) {
        this.currentValue = value
        this.initialDittoValue = value
      }
    }

    format(value: number) {
      return this.unitHandler.format(this.unit, 'float', value)
    }

    onValidChanged(valid: any) {
      this.valid = valid
    }

    /**
     * Called when the value of the UnitInput changes
     * @param value The new value of the unit input
     */
    onChange(value: number) {
      this.changed = true
      if (this.valid.valid) {
        this.$emit('value-changed', value)
        this.submitEventBus.$emit(events.scada.templates.itemChanged)
      }
    }

    protected onSubmitReceived() {
      if (this.changed && this.valid.valid) {
        this.submit()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';
  @import 'item';

  .form-feedback {
    text-align: right;
    flex: 0 0 100%;
  }
</style>
