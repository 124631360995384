<template>
  <b-dropdown
    :class="selectedSite != null ? 'active' : ''"
    boundary="viewport"
    class="flat-dropdown"
    menuClass="py-0 mt-2"
    noCaret
    toggleClass="d-flex align-items-center"
    variant="outline-primary"
    @show="clearSearch()"
  >
    <template #button-content>
      <span class="material-icons mr-1">factory</span>
      <span class="mr-auto">{{ siteName }}</span>
      <span class="material-icons">expand_more</span>
    </template>

    <div class="dropdown-wrapper">
      <b-dropdown-form formClass="m-0 p-0 ">
        <b-input-group>
          <b-input v-model="search" :placeholder="$t('search')" debounce="100" />
          <b-input-group-append v-if="search !== ''">
            <b-button class="ignore py-0" @click="search = ''">
              <span class="material-icons">clear</span>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-dropdown-form>
      <div class="scroll-container">
        <b-dropdown-item-button
          v-for="site in visibleSites"
          :key="site.id"
          :active="isActiveSite(site.id)"
          @click="goToSite(site.id)"
        >
          {{ site.name }}
        </b-dropdown-item-button>
      </div>
    </div>
  </b-dropdown>
</template>

<script lang="ts">
  import { Component, Watch } from 'vue-property-decorator'
  import type { Site } from '@/api/klempner/models/SiteListResponse'
  import Logger from '@/logger'
  import { mixins } from 'vue-class-component'
  import ApiMixin from '@/modules/tools/ApiMixin'
  import SiteMixin from '@/modules/ditto/SiteMixin'

  @Component
  export default class SiteSwitcher extends mixins(ApiMixin, SiteMixin) {
    sites: Site[] = []

    selectedSite: Site | null = null

    search = ''

    clearSearch() {
      this.search = ''
    }

    get visibleSites() {
      return this.sites.filter((site) => {
        if (site.name === '') {
          return false
        }
        const searchString = this.search.toLowerCase()
        return (
          [
            site.location.street ?? '',
            site.location.city ?? '',
            site.location.postalcode ?? '',
            site.name,
            site.description,
          ].find((value) => value.toLowerCase().includes(searchString)) !== undefined
        )
      })
    }

    get siteName() {
      return this.selectedSite?.name ?? null
    }

    isActiveSite(siteId: string) {
      return siteId === this.selectedSite?.id
    }

    goToSite(siteId: string) {
      this.search = ''
      if (this.selectedSite != null) {
        this.$router.push({ params: { id: siteId } })
      } else {
        this.$router.push({ name: 'SiteOverview', params: { id: siteId } })
      }
      this.$emit('navigate')
    }

    async mounted() {
      await this.fetchSites()
    }

    @Watch('currentSiteId')
    async refreshCurrentSite() {
      this.selectedSite = await this.fetchCurrentSite()
    }

    async fetchSites(): Promise<void> {
      this.sites = []
      try {
        this.sites = (
          await this.getSiteApiClient().list({
            filterIncludeHidden: false,
            sortField: 'name',
            sortDirection: 'asc',
            entriesPerPage: 'all',
          })
        ).sites
      } catch (e) {
        Logger.error('Could not load sites for navbar', e)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/vars';

  .font-size-lg {
    font-size: $font-size-lg !important;
  }

  .flat-dropdown {
    ::v-deep .btn:not(.ignore) {
      border-radius: $border-radius * 3.5;
      background: none !important;
      border: none !important;
      color: $gray-800;

      &:hover,
      &:active {
        color: $primary;
        background: desaturate(lighten($primary, 55%), 30%) !important;
      }

      &:focus {
        border: none !important;
        outline: none !important;
      }

      &:focus {
        border: none !important;
        box-shadow: none !important;
      }

      input:focus {
        box-shadow: none !important;
        border: none !important;
      }
    }

    &.active {
      ::v-deep .btn:not(.ignore) {
        color: $primary;
        background: desaturate(lighten($primary, 55%), 30%) !important;
      }
    }
  }

  .dropdown-wrapper {
    max-height: calc(100vh - 100px);
    min-width: 250px;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ::v-deep input {
      border-bottom-left-radius: 0;
      border-left: none;
      border-top: none;
    }

    ::v-deep button {
      border-bottom-right-radius: 0;
    }

    .scroll-container {
      min-height: 0;
      overflow-y: auto;
    }
  }
</style>
